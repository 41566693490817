<div
  elTestId="WgeuCMl5yZu0FGP6fvc0T"
  class="a-notification a-notification--banner -show"
  id="frontend-kit-notification-banner-subscription"
  role="alert"
  [class]="bannerType[type] === bannerType.WARNING ? '-warning' : '-info'"
  >
  <em
    elTestId="J9FbrwEVDWfEpf5f2wdI0"
    class="a-icon"
    [class]="
      bannerType[type] === bannerType.WARNING
        ? 'ui-ic-alert-warning'
        : 'ui-ic-alert-info'
    "
    title="Maintenance icon"
  ></em>
  @if (_html) {
    <div
      elTestId="Zu3YYids0AOfosPCSuH83"
      id="frok-banner-label"
      class="a-notification__content"
      >
      <div
        elTestId="BvxWWtXHDL-yjJVdXIWti"
        class="html-content"
        [innerHTML]="_html"
      ></div>
    </div>
  }
  @if (showCloseButton) {
    <button
      elTestId="tHajUdPDkrccf2pzGN0xv"
      type="button"
      class="a-button a-button--integrated -without-label"
      data-frok-action="close"
      aria-label="close banner"
      (click)="closeBanner()"
      >
      <em
        elTestId="7JlewTIcId0AXcczfjeHw"
        class="a-icon a-button__icon ui-ic-close"
        title="Close banner"
      ></em>
    </button>
  }
</div>
